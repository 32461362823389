import React, { useMemo } from 'react'
import { STRIDE_CHAIN_INFO } from '@/config'
import { assert } from '@/utils'
import {
  Swaps,
  SwapsCompletionSummary,
  TxnLifecycleHooks,
  WalletClient,
  WalletClientContextProvider,
  setSkipAPIClientID
} from '@leapwallet/elements'
import '@leapwallet/elements/styles.css'
import { useWalletClient } from '@cosmos-kit/react-lite'
import { StdSignDoc } from '@cosmjs/amino'
import { SignDoc } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { useStrideWallet, useWallet } from '@/contexts/wallet'

// This refers to Ethereum and only allowing USDC and ETH
const SOURCE_CHAINS = [
  // Ethereum
  {
    chainId: '1',
    assetDenoms: ['0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 'ethereum-native']
  },
  // Solana
  {
    chainId: 'solana',
    // USDC only - SOL is not yet supported
    assetDenoms: ['EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v']
  },
  // Base
  {
    chainId: '8453',
    // USDC and USDC.axl
    assetDenoms: ['0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', '0xEB466342C4d449BC9f53A865D5Cb90586f405215']
  },
  // Optimisim
  {
    chainId: '10',
    // USDC and USDC.axl
    assetDenoms: ['0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85', '0xEB466342C4d449BC9f53A865D5Cb90586f405215']
  },
  // Arbitrum
  {
    chainId: '42161',
    // USDC and USDC.axl
    assetDenoms: ['0xaf88d065e77c8cC2239327C5EDb3A432268e5831', '0xEB466342C4d449BC9f53A865D5Cb90586f405215']
  }
]

// This refers to Stride and only allowing stTIA
const DESTINATION_CHAINS = [{ chainId: STRIDE_CHAIN_INFO.chainId, assetDenoms: ['stutia', 'stadym'] }]

export interface TiaAirdropSwapBannerModalWidgetProps {
  denom: 'stTIA' | 'stDYM'
  onSwapStart: TxnLifecycleHooks<SwapsCompletionSummary>['onTxnSignApproved']
  onSwapSuccess: TxnLifecycleHooks<SwapsCompletionSummary>['onTxnComplete']
}

setSkipAPIClientID('elements-stride')

// Isolate component to simplify encapsulate all these widget details.
// It's very important we do not server-render this otherwise we'll run into weird import problems.
// It shouldn't affect anything either given this component is only opened through interactions.
const TiaAirdropSwapBannerModalWidget: React.FC<TiaAirdropSwapBannerModalWidgetProps> = ({
  denom,
  onSwapStart,
  onSwapSuccess
}) => {
  const { connect } = useWallet()

  const strideAccount = useStrideWallet()

  const { client } = useWalletClient()

  // Possibly due to outdated or version mismatch of cosmjs-types
  // @ts-expect-error
  const walletClient: WalletClient = useMemo(() => {
    return {
      enable: (chainIds: string | string[]) => {
        assert(client, 'Unable to get account without a client')
        assert(client.enable, 'Unable to enable chain without a client')
        return client.enable(chainIds)
      },
      getAccount: async (chainId: string) => {
        assert(client, 'Unable to get account without a client')
        assert(client.enable, 'Unable to enable chain without a client')
        assert(client.getAccount, 'Unable to get chain account without a client')
        await client.enable(chainId)
        const result = await client.getAccount(chainId)
        return {
          bech32Address: result.address,
          pubKey: result.pubkey,
          isNanoLedger: !!result.isNanoLedger
        }
      },
      getSigner: async (chainId: string) => {
        assert(client, 'Unable to get account without a client')
        assert(client.getOfflineSignerDirect, 'Unable to get account without a client')
        assert(client.getOfflineSignerAmino, 'Unable to get account without a client')

        const signer = client.getOfflineSignerDirect(chainId)
        const aminoSigner = client.getOfflineSignerAmino(chainId)

        return {
          signDirect: async (address: string, signDoc: SignDoc) => {
            const result = await signer.signDirect(address, signDoc)
            return {
              signature: new Uint8Array(Buffer.from(result.signature.signature, 'base64')),
              signed: result.signed
            }
          },
          signAmino: async (address: string, signDoc: StdSignDoc) => {
            const result = await aminoSigner.signAmino(address, signDoc)
            return {
              signature: new Uint8Array(Buffer.from(result.signature.signature, 'base64')),
              signed: {
                ...result.signed,
                account_number: BigInt(result.signed.account_number)
              }
            }
          }
        }
      }
    }
  }, [client])

  const walletConfig = {
    walletClient,
    connectWallet: () => connect(),
    userAddress: strideAccount?.address
  }

  return (
    <>
      <div className="leap-ui stride">
        <WalletClientContextProvider value={walletConfig}>
          <Swaps
            title="Cross Chain Swap"
            defaultValues={{
              sourceAsset: 'USDC',
              destinationChainId: 'stride-1',
              destinationAsset: denom
            }}
            allowedSourceChains={SOURCE_CHAINS}
            allowedDestinationChains={DESTINATION_CHAINS}
            txnLifecycleHooks={{
              onTxnSignApproved: onSwapStart,
              onTxnComplete: onSwapSuccess
            }}
          />
        </WalletClientContextProvider>
      </div>
    </>
  )
}

export { TiaAirdropSwapBannerModalWidget }
