import React, { useContext, useState } from 'react'
import { assert } from '@/utils'

interface TiaAirdropContextType {
  isOpen: boolean
  open: () => void
  close: () => void
}

const Context = React.createContext<TiaAirdropContextType | null>(null)

// There are two layers for the Widget state
// A: TiaAirdropProvider - takes care of modal state along with the banner
// B: TiaAirdropSquidWidget (this) - takes care of the commit state and hooks on to the widget events
const TiaAirdropSwapBannerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  return <Context.Provider value={{ isOpen, open, close }}>{children}</Context.Provider>
}

const useTiaAirdropSwapBanner = () => {
  const context = useContext(Context)
  assert(context, 'You forgot to mount TiaAirdropSwapBannerProvider')
  return context
}

export { TiaAirdropSwapBannerProvider, useTiaAirdropSwapBanner }
