import { useQuery } from '@tanstack/react-query'
import { CHAIN_INFO_LIST } from '@/config'
import { SelectedCoinDenom } from '@/wallet-utils'
import { redis } from '@/redis'
import { queryKeys } from '@/query-keys'

export interface ApyUnbondingPeriod {
  start: number
  end: number
  unit: string
}

export interface Apy {
  currentYield: number
  strideYield: number
  unbondingPeriod: ApyUnbondingPeriod
  fees: number
}

type DenomApyMap = Record<SelectedCoinDenom, Apy>

// As a workaround, we'll put placeholder values for denoms that are not part of SUPPORTED_COIN_DENOMS.
// Ideally, the value of `selectedCoinDenom` is the same as `SUPPORTED_COIN_DENOMS`.
// However, this is not the case at the moment. Trying to "fix" this here with type-guards is tedious and difficult.
// @TODO:Let's remove this in the future once we fix this type issue.
const PLACEHOLDER_APY = {
  currentYield: 0,
  strideYield: 0,
  unbondingPeriod: { start: 0, end: 0, unit: 'minutes' },
  fees: 0
}

const getUnbondingPeriod = ({ period, delay }: { period: string | null; delay: string | null }) => {
  const unbondingPeriod = Number(period || 0)
  const unbondingDelay = Number(delay || 0)
  const unbondingPeriodEnd = unbondingPeriod + unbondingDelay

  // Less than 1 hour, we display something like "40-50 minutes"
  if (unbondingPeriod < 60) {
    const start = unbondingPeriod
    const end = Math.ceil(unbondingPeriodEnd)
    return { start, end, unit: 'minutes' }
  }

  // Less than 24 hours, we display something like "1-2 hours"
  if (unbondingPeriod < 60 * 24) {
    const start = Math.ceil(unbondingPeriod / 60)
    const end = Math.ceil(unbondingPeriodEnd / 60)
    return { start, end, unit: 'hours' }
  }

  // We display something like 1-2 days
  const start = Math.ceil(unbondingPeriod / 60 / 24)
  const end = Math.ceil(unbondingPeriodEnd / 60 / 24)
  return { start, end, unit: 'days' }
}

const useStakeStatsQuery = () => {
  const getChainApy = async (denom: SelectedCoinDenom): Promise<Apy> => {
    const chainInfo = CHAIN_INFO_LIST[denom]

    const [currentYield, strideYield, unbondingPeriod, unbondingDelay, fees] = await Promise.all([
      redis.get<number>(`sheet_STAKING_APR_${denom}`),
      redis.get<number>(`sheet_STTOKEN_APR_${denom}`),
      redis.get<string>(`${chainInfo.chainId}-unbonding_period`),
      redis.get<string>(`${chainInfo.chainId}-unbonding_delay`),
      redis.get<number>(`${chainInfo.chainId}-fees`)
    ])

    return {
      currentYield: currentYield || 0,
      strideYield: strideYield || 0,
      unbondingPeriod: getUnbondingPeriod({ period: unbondingPeriod, delay: unbondingDelay }),
      fees: fees || 0
    }
  }

  const handleFetchApy = async (): Promise<DenomApyMap> => {
    // @TODO: Best way to handle this is probably by validating Object.fromEntries with z.record(z.enum(SelectedCoinDenom), Apy)
    // @ts-ignore
    const data = {} as DenomApyMap

    // @TODO: Refactor to probably use Object.entries/Object.fromEntries instead
    for (const denom of Object.keys(CHAIN_INFO_LIST)) {
      if (denom === 'STRD' || denom === 'SCRT') {
        data[denom] = PLACEHOLDER_APY
      } else {
        data[denom] = await getChainApy(denom)
      }
    }

    return data
  }

  return useQuery({
    queryKey: queryKeys.globalApy,
    queryFn: handleFetchApy
  })
}

export { useStakeStatsQuery }
