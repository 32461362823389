import { useEffect } from 'react'
import { usePlausible } from 'next-plausible'

export interface AnalyticsTrackerProps {
  eventName: string
  eventProps?: Record<string, any>
}

const AnalyticsTracker: React.FC<AnalyticsTrackerProps> = ({ eventName, eventProps }) => {
  const plausible = usePlausible()

  useEffect(() => {
    plausible(eventName, { props: { ...eventProps } })
  }, [eventName])

  return null
}

export { AnalyticsTracker }
