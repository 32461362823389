import { useQuery } from '@tanstack/react-query'
import { queryKeys } from '@/query-keys'
import { redis } from '@/redis'
import { fatal } from '@/utils'
import { SelectedCoinDenom, SelectedStakedCoinDenom } from '@/wallet-utils'

// A subset of both `SelectedCoinDenom` and `SelectedStakedCoinDenom`, only
// which denoms have liquids staking support. This also usually mean that it
// has proper cached usd values in redis.
//
// @TODO: Duplicated both here and in DustSweeper. Consider moving to `wallet-utils/types`
// when it makes sense / when we have a more appropriate use-case.
type AllSupportedDenom = SelectedCoinDenom | SelectedStakedCoinDenom | 'STRD'

const useTokenValueInUsdQuery = (denom: AllSupportedDenom) => {
  const handleRequest = async (): Promise<number> => {
    const value = await redis.get<number>(`sheet_COINGECKO_PRICE_DOLLAR_${denom}`)

    if (value == null) {
      throw fatal(`Unable to fetch price dollar value for ${denom}`)
    }

    return value
  }

  return useQuery({
    queryKey: queryKeys.globalTokenValueInUsdByDenom({ denom }),
    queryFn: handleRequest
  })
}

export { useTokenValueInUsdQuery }
