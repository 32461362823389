import React from 'react'
import { Image } from '@mantine/core'

interface ChainIconProps {
  size: number
  // We're not strictly checking this because we want to support denoms we simply don't support like USD, etc.
  denom: string
}

// We've decided to use our own SVGs because we need to support stToken icons
// which are not in the registry and allow for flexibility (if we need something
// in slightly different shape)
const ChainIcon: React.FC<ChainIconProps> = ({ denom, size }) => {
  // This is a really weird exception because Umee (now UX) does not provide an svg for their logo.
  // Let's keep this the exception rather than the rule as it will make things needlessly complicated.
  const extension = denom === 'UMEE' ? 'png' : 'svg'

  // We want chain icon to be stricly a square. Some logos, or at least the files we have,
  // like Osmosis, seem to have a slightly disproportionate sizes (e.g., 30x33).
  return (
    <Image src={`/chains/${denom}.${extension}`} alt={`Icon for ${denom}`} width={size} height={size} fit="contain" />
  )
}

export { ChainIcon }
