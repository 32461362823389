// Generic react imports
import React from 'react'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import { createStyles } from '@mantine/core'
import { useMediumScreen } from '@/hooks'

// @source https://www.radix-ui.com/primitives/docs/components/scroll-area
const useStyles = createStyles<string, { isMediumScreen: boolean }>((t, params) => ({
  root: {
    width: '100%',
    height: params.isMediumScreen ? 480 : 360,
    borderRadius: t.radius.sm,
    overflow: 'hidden',
    backgroundColor: 'white'
  },

  viewport: {
    width: '100%',
    height: '100%',
    borderRadius: 'inherit'
  },

  scrollbar: {
    display: 'flex',
    // ensures no selection
    userSelect: 'none',
    // disable browser handling of all panning and zooming gestures on touch devices
    touchAction: 'none',
    padding: 2,
    background: '#eee',
    transition: 'background 160ms ease-out',
    '&:hover': { background: '#ddd' },
    '&[data-orientation="vertical"]': { width: 10 }
  },

  thumb: {
    flex: 1,
    background: '#bbb',
    borderRadius: 10,
    // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      minWidth: 44,
      minHeight: 44
    }
  }
}))

const CoinSelectorScrollableList = ({ children }: React.PropsWithChildren) => {
  const { isMediumScreen } = useMediumScreen()

  const { classes } = useStyles({
    isMediumScreen
  })

  return (
    <ScrollArea.Root className={classes.root}>
      <ScrollArea.Viewport className={classes.viewport}>{children}</ScrollArea.Viewport>

      <ScrollArea.Scrollbar orientation="vertical" className={classes.scrollbar}>
        <ScrollArea.Thumb className={classes.thumb} />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  )
}

export { CoinSelectorScrollableList }
