import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/query-keys'
import { redis } from '@/redis'
import { assert } from '@/utils'

export type StakingReward = {
  denom: string
  reward: number
}

const useRewardsAprQuery = () => {
  const fetchRealRewardsApr = async () => {
    const stakingYieldKeys = await redis.get<string>('sheet_STRIDE_STAKING_YIELD_KEYS')

    assert(stakingYieldKeys, 'Missing value for sheet_STRIDE_STAKING_YIELD_KEYS')

    const convert = (value: number, denom: string) => {
      // I don't know why, but this works for now. Let's ask Kent or anyone
      // who may know if we run into any bugs or changes in the future.
      return ['STINJ', 'STEVMOS'].includes(denom) ? value / 1e12 : value
    }

    const rewardsQueries = stakingYieldKeys.split(',').map(async (denom: string) => {
      const rewardYield = await redis.get<number>(`sheet_STRD_STAKING_${denom}_YIELD`)

      // @TODO: Consider providing decimals - as a raw value, it makes more sense
      const reward = rewardYield ? convert(rewardYield, denom) * 100 : 0

      return { denom, reward }
    })

    const stakingRewards: StakingReward[] = await Promise.all(rewardsQueries)

    const strdRewards = stakingRewards.filter((reward) => reward.denom === 'STRD')[0]

    return {
      totalRewards: stakingRewards
        .filter((reward) => reward.denom !== 'STRD')
        .reduce((total, denom) => {
          return total + denom.reward
        }, 0),

      strdRewards: strdRewards.reward
    }
  }

  const handleRequest = async () => {
    const { totalRewards, strdRewards } = await fetchRealRewardsApr()

    return {
      apr: {
        staking: strdRewards,
        rewards: totalRewards,
        total: totalRewards + strdRewards
      }
    }
  }

  return useQuery({
    queryKey: queryKeys.globalRewardsApr,
    queryFn: handleRequest
  })
}

export { useRewardsAprQuery }
