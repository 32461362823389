interface HostZoneValidators {
  name: string
  address: string
  delegation_amt: string
  weight: string
  internal_weight_rate: number | null
  slash_query_progress_tracker: string
  slash_query_progress_checkpoint: string
  slash_query_in_progress: boolean
  delegation_changes_in_progress: string
  shares_to_tokens_rate: string
}

export interface HostZone {
  chain_id: string
  connection_id: string
  transfer_channel_id: string
  bech32prefix: string
  ibc_denom: string
  host_denom: string
  last_redemption_rate: string
  redemption_rate: string
  unbonding_frequency: string
  total_delegations: string
  address: string
  validators: HostZoneValidators[]
  halted: boolean
}

// @TODO: Rename to MultiSigHostZone
export interface TiaHostZone {
  chain_id: string
  native_token_denom: string
  native_token_ibc_denom: string
  transfer_channel_id: string
  delegation_address: string
  reward_address: string
  deposit_address: string
  redemption_address: string
  claim_address: string
  operator_address_on_stride: string
  safe_address_on_stride: string
  last_redemption_rate: string
  redemption_rate: string
  min_redemption_rate: string
  max_redemption_rate: string
  min_inner_redemption_rate: string
  max_inner_redemption_rate: string
  delegated_balance: string
  unbonding_period_seconds: string
  halted: boolean
}

export interface HostZoneResponse {
  host_zone: HostZone | TiaHostZone
}

export const isHostZone = (hostZone: HostZone | TiaHostZone): hostZone is HostZone => {
  // Took any distinct property from HostZone
  return 'ibc_denom' in hostZone
}

export const isTiaHostZone = (hostZone: HostZone | TiaHostZone): hostZone is TiaHostZone => {
  // Took any distinct property from TiaHostZone
  return 'native_token_ibc_denom' in hostZone
}
