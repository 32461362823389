import { useQuery } from '@tanstack/react-query'
import { stride } from 'stridejs'
import { SelectedCoinDenom } from '@/wallet-utils'
import { queryKeys } from '@/query-keys'
import { fatal } from '@/utils'
import { AIRDROP_IDENTIFIERS, STRIDE_CHAIN_INFO, AIRDROP_IDENTIFIER_DENOMS } from '@/config'
import { useStrideWallet } from '@/contexts/wallet'

// While useQualifiedChains is a superset and more useful, this query was introduced
// much earlier (a few months prior Stride's launch), and is a lot more lightweight.
// Plenty useful for most cases where we simply need the list or number of airdrops
// the user is eligible for.
const useEligibleAirdropsQuery = () => {
  const strideAccount = useStrideWallet()

  const handleRequest = async (): Promise<SelectedCoinDenom[]> => {
    if (!strideAccount) {
      throw fatal('Unable to request eligible airdrops while wallet is disconnected.')
    }

    const client = await stride.ClientFactory.createRPCQueryClient({
      rpcEndpoint: STRIDE_CHAIN_INFO.rpc
    })

    const payload = await Promise.all(
      AIRDROP_IDENTIFIERS.map(async (identifier) => {
        const { claimRecord } = await client.stride.claim.claimRecord({
          address: strideAccount.address,
          airdropIdentifier: identifier
        })

        return { denom: AIRDROP_IDENTIFIER_DENOMS[identifier], claimRecord }
      })
    )

    const eligibleAirdrops = payload
      .filter((payload) => {
        // Filter out non-eligible airdrops
        // -> [{ denom, claimRecord }, { denom, claimRecord }, { denom, claimRecord }]
        // -> [{ denom, claimRecord }, { denom, claimRecord }]
        return Boolean(payload.claimRecord.actionCompleted.length)
      })
      .map((payload) => {
        // We only care about the list of airdrops as denom
        // -> [{ identifier, claimRecord }, { identifier, claimRecord }]
        // -> ['ATOM', 'EVMOS']
        return payload.denom
      })

    return eligibleAirdrops
  }

  return useQuery({
    queryKey: queryKeys.stakeFormEligibleAirdropsByAddress({ address: strideAccount?.address ?? '' }),
    queryFn: handleRequest,
    enabled: Boolean(strideAccount),
    retry: false
  })
}

export { useEligibleAirdropsQuery }
