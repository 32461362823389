import axios from 'axios'
import { z } from 'zod'
import { useQuery } from '@tanstack/react-query'
import { STRIDE_CHAIN_INFO, CHAIN_INFO_LIST } from '@/config'
import { SelectedCoinDenom } from '@/wallet-utils'
import { queryKeys } from '@/query-keys'

export interface TvlPayload {
  tvl: string
}

const DAILY = 1000 * 60 * 24

// This fetches the tvl for a given denom. Similar to useHostZone, this is a very low-level query,
// and you will have to use this on top of `useHostZoneQuery` and `useTokenValueInUsdQuery` to get
// the tvl of the st token in USD.
//
// Previously, we used the edge server to fetch the tvl, but Numia indexes less frequently from our needs.
const useTvlQuery = (denom: SelectedCoinDenom) => {
  const handleRequest = async (): Promise<TvlPayload> => {
    const instance = axios.create({
      baseURL: STRIDE_CHAIN_INFO.rest
    })

    const { coinMinimalDenom } = CHAIN_INFO_LIST[denom].stakeCurrency

    const response = await instance.get(`cosmos/bank/v1beta1/supply/by_denom?denom=st${coinMinimalDenom}`)

    const supply = supplyResponseSchema.parse(response.data)

    return {
      tvl: supply.amount.amount
    }
  }

  return useQuery({
    queryKey: queryKeys.globalTvlByDenom({ denom }),
    queryFn: handleRequest,
    refetchInterval: DAILY,
    // Unfortunately, we disabled this on the provider-level. We probably should not do that.
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
    refetchOnMount: true,
    refetchOnReconnect: true
  })
}

const supplyResponseSchema = z.object({
  amount: z.object({
    denom: z.string(),
    amount: z.string()
  })
})

export { useTvlQuery }
